<template>
  <div class="pt-5 border-top border-primary">
    <!-- Copyright -->
    <div class="footer-copyright text-center py-3">
      © {{ year }} Copyright:
      <a class="text-grey" href="https://www.torquetogether.com"
        >Torque Motorsport</a
      >
    </div>
    <!-- Copyright -->
    <!-- button to top -->
    <div class="text-center">
      <a href="#" class="btn btn-primary">Back To Top</a>
    </div>
    <!-- button to top -->
  </div>
</template>

<script>
export default {
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>
